<template>
  <div class="dimbox showImage">
    <div class="mHeader">
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <img :src="image" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
